import React from 'react'
import GameRoute from './GameRoute'
import pop from '../../images/pop_up.jpg'
import cross from '../../images/cross.png'
import { useState, useEffect } from 'react'
import { useCallback } from 'react'
import { BASE_URL, checkAndHandleUserId } from '../AllApi/CommonUrl';
import { useNavigate } from 'react-router-dom';
import { Checkstatus } from '../AllApi/CheckStatus';
import Cookies from 'js-cookie';
const Home = () => {
  const [isset, Setisset] = useState(false);
  const navigate = useNavigate();
  const fetchData = useCallback(() => {
    if(isset){
    checkAndHandleUserId(navigate);
    Setisset(!isset);
  }
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);




  const generateRandomId = async() => {
    if (window && window.ethereum) {
      if (window.ethereum.isMiniPay) {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
         return accounts[0];
      }
      }
      // return '0x7071E17A089Bc6cED07BBAAd75887A318061#RT$!@112#11'
  };
  useEffect(() => {
    ; (async () => {
      const address = await generateRandomId()
      try {
        const endpoint='CoinCheckin'
          const Res = await Checkstatus(BASE_URL,endpoint,address);
          console.log('rsdf',Res)
          if(Res.check_in_screen==0){
           navigate('/Checkin')
          }
        }
         catch (error) {
        console.log(error)
       }
    })()
  },[]); 



  // useEffect(() => {
  //   ; 
  //   let isMounted = true;
  //   (async () => {
  //     if (isMounted) {
  //     checkAndHandleUserId(navigate);
  //     }
  //     //     const elem = document.getElementById('modal1');
  //     // const instance = window.M.Modal.init(elem, { dismissible: false });
  //     // instance.open();
  //   })()
  //   return () => {
  //     isMounted = false;  // cleanup when component unmounts
  //   };
  // },[1]);
  return (
    <>
      <GameRoute></GameRoute>
    </>
  )
}

export default Home
