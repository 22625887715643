import React from 'react'
import img from '../../images/menu_ic.svg'
import logo from '../../images/logo.png'
import wallet from '../../images/wallet_ic_h.png'
import users from '../../images/play_solo.png'
// import setting from '../../images/setting.svg'  
import setting from '../../images/hamburge.svg'  

import dmd from '../../images/diamond.png'
import { NavLink } from 'react-router-dom'
import {useState } from 'react'
import Cookies from 'js-cookie';
const Navbar = (props) => {
  console.log(props.array)
  const[toggle,Settoggle]=useState(false);
  return (
   <>
   <div className="header">
  <nav>
    <div className="h_left">
    <NavLink to={"/"}>
        <img src={logo} alt="menu" className="logo" />
      </NavLink>
    </div>
    <div className="h_right">
      <div className="h_right">

     {props.status==0&&<NavLink to={`/Progress/${0}`}className={"a_width"}>
        <img src={dmd} alt="wallet" className='daim_img'/>
        <p className='r_infogam'>{props.dmd}</p>
        <img src={wallet} alt="wallet" className='wallet_ic22'/>
      </NavLink>}
      {props.status==1&&<NavLink className={"a_width disable"}>
        <img src={dmd} alt="wallet" className='daim_img'/>
        <p className='r_infogam'>{props.dmd}</p>
        <img src={wallet} alt="wallet" className='wallet_ic22'/>
      </NavLink>}
      {/* {!props.array &&<NavLink to={"/Profile"} >
        <img src={users} alt="wallet"  className='profile_img_hm'/>
      </NavLink>} */}
        <img src={setting} alt="wallet"  class='dropdown-trigger btn' data-target='dropdown1' className='profile_img_hm'onClick={(e)=>Settoggle(!toggle)}/>
    </div>
    </div>
  </nav>
  {toggle&&
  <div>
  <ul id='dropdown1' class='dropdown-content'>
    <li><NavLink to={"/Profile"}>My Profile</NavLink></li>
    <li class="divider"></li>
     <li><NavLink to={"/howplay"}>How To Play</NavLink></li>
     <li class="divider"></li>
    <li><NavLink to={'/video'}>Watch Video</NavLink></li>
    <li class="divider"></li>
    <li><NavLink to='javascript:void(0)'
      onClick={() => window.location = 'mailto:hello@mchamp.com'}>Contact Us</NavLink></li>

  </ul></div>}
</div>
     
   </>
  )
}

export default Navbar
