import React from 'react'
import Right from '../header/Right'
import CateHeader from '../header/CateHeader'
import { NavLink } from 'react-router-dom'
import QuizFooter from '../footer/QuizFooter'
const FAQ = () => {
  return (
    <>
   <div className="section">
  <div className="s_left">
    <div className="header">
      <CateHeader name={'FAQ'} path={'/gameroute/1'}></CateHeader>
    </div>
    {/* how to play content*/}
    <div className="htplay game_det">
      <h2 className="text_bg redd">Q.1: What is a Game Tournament?</h2>
      <div className="htP_text">
        <p>
          Ans: Game tournament, is a Crypto gaming platform. Where users can
          participate using the cryptocurrency and based on their performance.
          Can win big crypto.
        </p>
      </div>
      <h2 className="text_bg redd">
        Q.2: How can I participate in the Game Tournament?
      </h2>
      <div className="htP_text">
        <p>
          Ans: Participation in games can be done by using coins. Each game has
          a different entry fee.
        </p>
      </div>
      <h2 className="text_bg redd">Q.3: Where can I get the coins?</h2>
      <div className="htP_text">
        <p>
          Ans: Coins can be purchased using an option available in the Wallet
          section.
        </p>
      </div>
      <h2 className="text_bg redd">
        Q.4: How many times can I play the game daily?
      </h2>
      <div className="htP_text">
        <p>Ans: Each game tournament can only be played once.</p>
      </div>
      <h2 className="text_bg redd">
        Q.5: What is the minimum user participation required in each Game
        Tournament?
      </h2>
      <div className="htP_text">
        <p>
          Ans: Minimum 5 users are required to participate in the Game
          Tournament, else the tournament will be cancelled, and an amount will
          be refunded to participating user’s wallet.
        </p>
      </div>
      <h2 className="text_bg redd">Q.6: What are 5 levels in each Game?</h2>
      <div className="htP_text">
        <p>
          Ans:There are 5 levels for each game. Users must play all 5 levels.
           The scores from all the five levels will be added and considered as the final score for the user for that game tournament.
        </p>
      </div>
      <h2 className="text_bg redd">
        Q.7: When will the leaderboard be created?
      </h2>
      <div className="htP_text">
        <p>Ans: The leaderboard is created after the Game tournament ends.</p>
      </div>
      <h2 className="text_bg redd">
        Q.8: Which cryptocurrency is used for the transaction?
      </h2>
      <div className="htP_text">
        <p>Ans: We use cUSD for transactions.</p>
      </div>
      <h2 className="text_bg redd">Q.9: What is a Coin?</h2>
      <div className="htP_text">
        <p>
          Ans: Coin is an internal value used by the site and is used for
          participating in game tournaments. It can be purchased through cUSD
          from the wallet section.
        </p>
      </div>
      <h2 className="text_bg redd">Q.10: What is a Diamond?</h2>
      <div className="htP_text">
        <p>
          Ans: Diamond is an internal value used by the site. It can be&nbsp;won
          by&nbsp;users by getting listed on the leaderboard.
        </p>
      </div>
      <h2 className="text_bg redd">Q.11: How can I redeem diamonds?</h2>
      <div className="htP_text">
        <p>
          Ans: When the user wins the diamond, it gets added to the user's
          wallet. There is a redeem button below the Diamond amount. When the
          user clicks the redeem button, the diamonds get converted to cUSD and
          transferred to the user's crypto wallet.
        </p>
      </div>
      <h2 className="text_bg redd">Q.12: Daily Reward Policy ? </h2>
      <div className="htP_text">
        <p>
          Ans: If your coins is less than 2500 then you will get 2500 coin as reward .
        </p>
      </div>
    </div>
    <QuizFooter></QuizFooter>
  </div>
  <Right></Right>
</div>

    </>
  )
}

export default FAQ
