import React from 'react'
import { useState, useEffect, useRef} from 'react';
import Swal from 'sweetalert2';
import QuizFooter from '../footer/QuizFooter';
import Right from '../header/Right';
import gameh from '../../images/TopBan1.jpg'
import Cookies from 'js-cookie';
import { BASE_URL} from '../AllApi/CommonUrl';
import { useNavigate } from 'react-router-dom';
import { CoinAdd } from '../AllApi/AddCoin';
import { PayCoin } from '../AllApi/DefaultPayApi';
import {
  http,
  type Address,
  type Hash,
  type TransactionReceipt,
  createPublicClient,
  createWalletClient,
  custom,
  parseEther,
  parseUnits,
  encodeFunctionData,
  stringify,
  getContract,
  formatEther,
} from 'viem'
import 'viem/window'
import loader from '../../images/loader.gif'
//import loader from '../images/loader.gif'
const { celo } = require("viem/chains");
const { stableTokenABI } = require("@celo/abis");

const PayZero = () => {
  //payment code//
  const [address, setAddress] = useState('');
  const [waccount, setWaccount] = useState('');
  const [hash, setHash] = useState();
  const [receipt, setReceipt] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [ClickValue, SetClickvalue] = useState(false);
  const [load, SetLoad] = useState(false);	
  const user_id = Cookies.get('userId')
  const tok = Cookies.get('idtoken')
  const nav =useNavigate()
  const generateRandomId = async() => {
    if (window && window.ethereum) {
      if (window.ethereum.isMiniPay) {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });
         return accounts[0];
      }
      }
      //return '0x7071E17A089Bc6cED07BBAAd75887A318061#RT$!@112#11'
  };

  const STABLE_TOKEN_ADDRESS = "0x765DE816845861e75A25fCA122bb6898B8B1282a";
    const CheckBalance=async(publicClient, address)=>{
      let StableTokenContract = getContract({
        abi: stableTokenABI,
        address: STABLE_TOKEN_ADDRESS,
        publicClient,
    });
  

    let balanceInBigNumber = await StableTokenContract.read.balanceOf([
        address,
    ]);
  
    let balanceInWei = balanceInBigNumber.toString();
  
    let balanceInEthers = formatEther(balanceInWei);
  
    return balanceInEthers;
      
    }
    const publicClient = createPublicClient({
      chain: celo,
      transport: http(),
    }); // Mainnet
    const walletClient = createWalletClient({
  	chain: celo,
  	transport: custom(window.ethereum),
   })
    const GetAddress = async() => {
      if (window && window.ethereum) {
      
        if (window.ethereum.isMiniPay) {
          // User is using Minipay
      
          // Requesting account addresses
          let accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            params: [],
          });
          // Injected wallets inject all available addresses,
          // to comply with API Minipay injects one address but in the form of array
          //console.log(accounts[0]);
           return accounts[0];
        }
        }
    };

    //const connect = async () => {
     //const [address] = await walletClient.requestAddresses()
     //setAccount(address)
   // }

    //funciton start//
    const AmountDeduct = async (address,totbalance,amount) => {

    try {	    
	    var resp = "failed";
    	const [waddress] = await walletClient.requestAddresses()
    	setWaccount(waddress);
	 
    	if (!waddress) return "failed"
    	const hash = await walletClient.sendTransaction({
		account: waddress,
    		to: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
    		data: encodeFunctionData({
      		abi: stableTokenABI, // Token ABI can be fetched from Explorer.
      		functionName: "transfer",
      	args: [
        	'0x0D014767A117ECa45B434b1D646558E5e65e8513',
        	// Different tokens can have different decimals, cUSD (18), USDC (6)
        	parseUnits(`${Number(amount)}`, 18),
      	],
    	}),
    	// If the wallet is connected to a different network then you get an error.
    	//ichain: celoAlfajores,
    	chain: celo,
    	})
    	setHash(hash)
 

      	if (hash) {
        	const receipt = await publicClient.waitForTransactionReceipt({ hash })
        	setReceipt(receipt)

		if (receipt.status === "success") {
			//alert("Transaction Success");
			resp = "success";
  	  	// Do something after transaction is successful.
  		} else {
			//alert("500");
    		// Do something after transaction has failed.
  		}

      	}

      } catch (error) {
        //nav(`/ProductDetails/${id}`)
      }    
	return resp;

    }	

  //payment code end//


     const SaveCoin = async (quantity) => {
        const address = await generateRandomId()
      try {
        const endpoint = '/CoinCheckin/check_in'
        const result = await PayCoin(BASE_URL,endpoint,address);
        console.log('redeem f', result);
        nav("/");
      }
      catch { }
    }


    const Pay  = (balance) => {
	  SetLoad(true);
	  SetClickvalue(true);
          const myFirstPromise = new Promise((resolve, reject) => {
            resolve( GetAddress())
          });
          myFirstPromise.then((successMessage) => {
            setAddress(successMessage);
            const resPromis = new Promise((resolve, reject) => {
              resolve( CheckBalance(publicClient,successMessage))
            });
            resPromis.then((totbalance) => {
              if(totbalance>=(balance)){
                const deduct = new Promise((resolve, reject) => {
                  resolve(AmountDeduct(address,totbalance,balance))
                });
                deduct.then((message) => {
                  //alert(message)
                  if(message==="success"){
			 // alert(user_id);
                         SaveCoin()
                  }
                });
              }
            });
          });
    };

    const CheckingAlert = () => {
        Swal.fire({
          title: "Claim for your daily reward",
          text:"2500 Coins",
          showDenyButton: false,
          confirmButtonText: "Claim",
          allowOutsideClick: false
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {  
            Pay(0)
          }
          else if (result.isDenied) {
          }
        });
      }
     useEffect(() => {
        CheckingAlert()
      },[]);
  return (
   <>
   <div className="section">
         <div className="s_left">
              { load &&<div className='center_vh'><img src={loader}/></div>}  
	  </div>
    <Right></Right>

    </div>
   </>
  )
}

export default PayZero
