
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
export const PayCoin = async (BASE_URL,endpoint,address) => {
      try {
        const Url = `${BASE_URL}/${endpoint}`;
      const rawData = {
              "android_id":address,
     }
      const response = await fetch(Url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rawData),
      });
      if (!response.ok) {
        throw new Error('some error');
      }
      const jsonData = await response.json();  
      console.log(jsonData)
      return jsonData;
          } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    };
    